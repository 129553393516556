import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import Select from "react-select";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import { Layout, Menu } from "antd";
import logo from "../../../src/swastikLogo.png"
import {
  ToolOutlined,
  MenuUnfoldOutlined,
  ShoppingCartOutlined ,
  SettingOutlined,
  UserOutlined,
  UserAddOutlined,
  FileTextOutlined,
  FileSyncOutlined,
  DashboardOutlined,
  TeamOutlined,
} from "@ant-design/icons";
import { site } from "@/redux/site/actions";
import { request } from "@/request";
import history from "@/utils/history";

import storePersist from "@/redux/storePersist";

const { Sider } = Layout;
const { SubMenu } = Menu;

const jsonObj = {
  'customer': 'Customer',
  'indent': 'Indent',
  'addindent': 'AddIndent',
  'editPO': 'editPO',
  'purchaseorder': 'Purchase Order',
  'vendor': 'Vendor',
  'site': 'Site',
  'admin':"Admin Management",
  'inventory': "Inventory",
  'item': "Item",
  'reports': "Reports"
}

function Navigation() {
  const [collapsed, setCollapsed] = useState(false);
  const [siteList, setSiteList] = useState([]);
  const [siteName, setSiteName] = useState('');
  const [access, setAccess] = useState([]);
  const [activeMenu, setActiveMenu] = useState("1");
  const dispatch = useDispatch();
  const location = useLocation();

  console.log('location ',location);
  const auth = storePersist.get('auth');

  const onCollapse = () => {
    setCollapsed(!collapsed);
  };
 
  const handleSiteChange = (e) => {
    setSiteName({'label' : e.siteName,
    'value' : e._id});
    dispatch(site.setSiteName(e));
    setActiveMenu("1");
    
    history.push({
      pathname : '/'
    });
  };

  const getSiteList = async () => {
    const jsonData = {
      sites: auth.current?.siteList
    }
    const data = await request.siteList('site', {...jsonData});
    const siteList = data.result?.map(res =>{
      res['label'] = res.siteName;
      res['value'] = res._id;
      return res;
    });

    setSiteList([...siteList]);
    setSiteName(siteList[0]);
    dispatch(site.setSiteName(data.result[0]));  
  };
  
  const handleChangeMenu = (value) => {
     setActiveMenu(value.key);
  }
 
  const getRoleList = async () => {
    const data = await request.list('roles', {});
  
    const auth = storePersist.get("auth");
    const list =  data.result?.filter(res => res.roleId === auth?.current?.role);
    const accessStr = list?.length > 0 ? list[0]?.access : '';
  
    const pathName = location.pathname?.split("/");
    accessStr.split(',').forEach((res,index) => {
      if(Array.isArray(pathName) && pathName.length > 0 && (pathName[1].trim() === res.trim())) setActiveMenu(`"${index+2}"`);
    })

    setAccess([...accessStr.split(',')]);
  }

  useEffect(() => {
    getSiteList();
    getRoleList();
  }, []);

  return (
    <>
      <Sider
        collapsible
        collapsed={collapsed}
        onCollapse={onCollapse}
        style={{
          zIndex: 1000,
          background: "#ebebeb",
          boxShadow: "1px 1px 10px lightgray"
        }}
      >
           <Select
                defaultValue={siteList[0]}
                // value={siteList}
                value={siteName}
                onChange={(e) => handleSiteChange(e)}
                name="mDesc"
                options={siteList}
            />
        <img src={logo} alt="builder" className="swastikLogo" />
        <Menu theme="light" selectedKeys={[activeMenu]} defaultSelectedKeys={[activeMenu]} onClick={(e) =>  handleChangeMenu(e)} mode="inline" style={{background: "#ebebeb"}}>
          <Menu.Item key="1" icon={<DashboardOutlined style={{fontSize:22}} />}>
            <Link to="/" /> 
            Home Page
          </Menu.Item>

         { access.map((res,index) => 
            <Menu.Item key={`"${index+2}"`} icon={<FileTextOutlined style={{fontSize:22}} />}>
              <Link to={`/${res.trim()}`} />
              {jsonObj[res.trim()]}
            </Menu.Item>
          )}
          
          {/* <Menu.Item key="2" icon={<MenuUnfoldOutlined style={{fontSize:22}}/>}>
            <Link to="/indent">Indent</Link>
          </Menu.Item>
          <Menu.Item key="4" icon={<FileTextOutlined style={{fontSize:22}} />}>
            <Link to="/vendor" />
            Vendor
          </Menu.Item>
          <Menu.Item key="5" icon={<FileSyncOutlined style={{fontSize:22}} />}>
            <Link to="/site" />
            Site
          </Menu.Item>
          <Menu.Item key="6" icon={<TeamOutlined style={{fontSize:22}} />}>
            <Link to="/admin" />
            Admin Management
          </Menu.Item>
          <Menu.Item key="7" icon={<ShoppingCartOutlined style={{fontSize:22}} />}>
            <Link to="/purchaseorder" />
            Purchase Order
          </Menu.Item>
          <Menu.Item key="8" icon={<SettingOutlined style={{fontSize:22}} />}>
            <Link to="/inventory" />
            Inventory
          </Menu.Item>
          <Menu.Item key="9" icon={<ToolOutlined style={{fontSize:22}} />}>
            <Link to="/reports" />
            Reports
          </Menu.Item> */}

          {/* { auth.current?.role <= 2 &&
            <Menu.Item key="36" icon={<FileTextOutlined />}>
              <Link to="/indent" />
              Indent
            </Menu.Item>
          } */}
          {/* <Menu.Item key="37" icon={<FileTextOutlined />}>
            <Link to="/purchaseorder" />
            Purchase Order
          </Menu.Item>
          <Menu.Item key="38" icon={<SettingOutlined />}>
            <Link to="/inventory" />
            Inventory
          </Menu.Item>
          <Menu.Item key="39" icon={<FileSyncOutlined />}>
            <Link to="/reports" />
            Reports
          </Menu.Item> */}
        </Menu>
      </Sider>
    </>
  );
}
export default Navigation;
