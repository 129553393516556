import * as actionTypes from "./types";

const INITIAL_REF_STATE = {
  result: [],
  selected: null,
  isLoading: false,
  isSuccess: false,
};
const INITIAL_STATE = {};

const siteReducer = (state = INITIAL_STATE, action) => {
  const { payload = null, keyState = null } = action;
  switch (action.type) {
    case actionTypes.SET_SITE_NAME:
      console.log('payload ',payload);
      return {
        ...state,
        name: payload
      };
    default:
      return state;
  }
};

export default siteReducer;
